// Color mapping as per https://projects.invisionapp.com/share/B24RC2YZC#/screens/114262213.
$highlight-blue: #5697EA !default;
$background-blue: #5697EA !default;
$light-blue2: #94a4b2;
$green: #019A01 !default;
$highlight-green: #019A01 !default;
$background-green: #019A01 !default;
$highlight-orange: #ffaa00 !default;
$highlight-yellow-1: #EAAA46;
$highlight-yellow-2: #FBE186;
$highlight-yellow-3: #FEDF49;
$highlight-orange-1: #faab14;
$background-orange: #ffa500 !default;
$text-color: #313840;
$background-gray-1: #555E67;
$supporting-1: #555E67;
$supporting-2: #CFD2DC;
$supporting-3: #DEE2E8;
$supporting-4: #8d909b;
$supporting-5: #99a6b4;
$background-gray-2: #F3F4F8;
$background-gray-3: rgba(221, 226, 232, 0.3);
$background-gray-4: #f8f9fc;
$background-gray-5: #eff1f4;
$background-gray-6: rgba(221, 226, 232, 0.1);
$background-gray-7: #ECEEF2;
$background-gray-8: #fafafa;
$gray-lighter: lighten(#000, 93.5%) !default; // #eee
$light-grey: #f2f2f2;
$white: #FFFFFF;
$white-30: rgba($white, .3);
$white-80: rgba($white, .8);
$background-gray-1-transparent-1: rgba(85, 94, 103, 0.65);
$background-gray-1-transparent-2: rgba(85, 94, 103, 0.5);
$background-gray-1-transparent-3: rgba(85, 94, 103, 0.95);
$background-gray-1-transparent-4: rgba(58, 65, 72, 0.6);
$background-gray-1-transparent-5: rgba(85, 94, 103, .5);
$supporting-2-transparent-0: rgba(207, 210, 220, .075);
$supporting-2-transparent-1: rgba(207, 210, 220, .2);
$supporting-2-transparent-2: rgba(207, 210, 220, .35);
$supporting-2-transparent-3: rgba(207, 210, 220, .55);
$supporting-2-transparent-4: rgba(207, 210, 220, .85);
$supporting-2-not-transparent-4: rgba(207, 210, 220, 1);
$generic-semi-transparent: rgba(35, 35, 35, 0.5);
$generic-2-semi-transparent: rgba(62, 62, 62, 0.5);
$generic-semi-transparent-hover: rgba(35, 35, 35, 0.8);
$light-blue: #63A1DC;
$faint-green: #72bb6c;
$black: #000000;
$black-3: rgba($black, .03);
$black-4: rgba($black, .1);
$black-5: rgba($black, .05);
$black-20: rgba($black, .2);
$black-50: rgba($black, .5);
$error-border: red;
$glacial-blue: #4196e7;
$sky-blue: #39d4f7;
$forest-green: #1aa317;
$emerald: #26EE9A;
$gray-text: #5f6870;
$action-gray-text: #A7ABB9;
$gray-tab: #EEEFF3;
$dark-blue: #111E4C;
$warning-color: #D16543;


// Administration toolbar height.
$toolbar-height-m: 101px;
$toolbar-height-l: 29px;

// Logo dimensions.
$logo-width-m: 140px;
$logo-width-l: 160px;
$logo-height-m: 45px;
$logo-height-l: 60px;

// Welcome components border-radius.
$welcome-border-radius: 5px;

// Modals
$modalFlank: 20px;

// Global container variables
$global-width: 1255px;
$global-padding-mobile: 30px;
$global-padding-desktop: 30px;

// Font variables
/* For anyone working in the future on this project.
 * $lisa- font sizes are what works best for her laptop screen resolution to
 * replace the according font size.
 */
$extra-small-text: 12px;
$small-text: 14px;
$text-font-size: 16px;
$global-font-size: 18px;
$lisa-medium-text: 22px;
$medium-text: 28px;
$lisa-big-text: 36px;
$big-text: 42px;
$huge-text: 48px;
$action-title-text: 50px;
$my-region-stat: 65px;

// Custom sections
$why-join-width: 1155px;
