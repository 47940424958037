// Global mixins.

// Center a background-image property.
@mixin center-background() {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

// Center element using flexbox.
@mixin center-flex() {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

// Fixed centering.
@mixin center-fixed() {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Toggle icon properties.
@mixin toggle-spacing() {
  margin: 0 0 3vh;
}

// Stat separators.
@mixin stat-separator($position) {
  $stroke-width: 1px;

  content: '';
  top: 0;
  border-radius: 5px;
  display: block;
  width: 90%;
  height: 1px;
  position: absolute;
  left: 50%;
  background-color: $supporting-2-transparent-4;
  transform: translateX(-50%);

  @media (min-width: $tablet) {
  }

  @media (min-width: $tablet) {
    width: $stroke-width;
    height: calc(100% - 80px);
    top: 40px;
    bottom: initial;
    transform: none;

    @if $position == 'left' {
      left: -#{$stroke-width};
    } @else {
      right: -#{$stroke-width};
      left: initial;
    }
  }

  @if $position == 'left' {

  } @else {
    & {
      top: initial;
      bottom: 0;
    }

    @media (min-width: $tablet) {
      bottom: -#{$stroke-width};
    }

  }

}

// Global box-shadow.
@mixin global-box-shadow {
  box-shadow: 0 10px 10px 0 $supporting-2-transparent-1;
}

// Global card.
@mixin global-card {
  border-radius: 5px;
  border: 2px solid $supporting-2-transparent-2;
}

// Set container.
@mixin container {
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-left: 0;
  padding-right: 0;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: $laptop) {
    max-width: $laptop-l;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  @media (min-width: 1200px) {
    max-width: $laptop-l;
    width: 100%;
  }
}

// Box with shadow style.
@mixin box {
  @include global-card;
}

// Links styles
@mixin link {
  text-decoration: none;
  transition: all .4s;
  cursor: pointer;
}

@mixin checkbox {
  position: relative;
  margin-bottom: 15px;

  input[type="checkbox"]:not(old) {
    width: 100%;
    height: 15px;
    opacity: 0;
    cursor: pointer;
    margin: 0;
    position: absolute;
    top: 3px;
    left: 0;

    &:checked + label:before,
    &:checked + span:before {
      box-shadow: inset 0 0 1px 2px white;
      background: $text-color;
    }

    + label,
    + span {
      cursor: pointer;

      &:before {
        content: '';
        width: 15px;
        height: 15px;
        border: 1px double black;
        cursor: pointer;
        vertical-align: top;
        display: inline-block;
        margin-right: 9px;
      }

    }
  }

}

@mixin radioButton {
  position: relative;
  margin-bottom: 15px;

  input[type="radio"]:not(old) {
    width: 100%;
    height: 15px;
    opacity: 0;
    cursor: pointer;
    margin: 0;
    position: absolute;
    top: 3px;
    left: 0;

    &:checked + label:before,
    &:checked + span:before {
      box-shadow: inset 0 0 1px 2px white;
      background: $text-color;
    }

    + label,
    + span {
      cursor: pointer;

      &:before {
        content: '';
        width: 15px;
        height: 15px;
        border: 1px double black;
        border-radius: 50%;
        cursor: pointer;
        vertical-align: top;
        display: inline-block;
        margin-right: 5px;
      }

    }
  }

}

@mixin uppercase-header($color) {
  text-transform: uppercase;
  font-size: 13px;
  line-height: 140%;
  color: $color;
  font-weight: normal;
}

@mixin blue-button {
  @include link;
  display: inline-block;
  background: $highlight-blue;
  color: #fff;
  font-weight: bold;
  padding: 10px;
  border: {
    color: $highlight-blue;
    radius: 4px;
    style: solid;
  }
  text: {
    decoration: none;
    transform: uppercase;
  }

  &:hover, &:focus, &.selected {
    background-color: darken($highlight-blue, 15%);
    border-color: darken($highlight-blue, 15%);
  }
}

@mixin wider-button {
  & {
    padding: 10px 20px;
  }
}

@mixin small-button {
  padding: 5px;
  white-space: normal;
  word-break: break-word;
}

@mixin cancel-blue-button {
  @include blue-button;
  & {
    color: $highlight-blue;
    border-color: $highlight-blue;
    background-color: #FFF;
  }
  &:hover, &:focus {
    background-color: darken($highlight-blue, 15%);
    border-color: darken($highlight-blue, 15%);
    color: #FFF;
  }
}

// Blue CTA.
@mixin cta($color) {
  @include global-box-shadow;

  display: inline-block;
  background-color: $color;
  color: $white;
  appearance: none;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  transition: all .4s;
  cursor: pointer;
  outline: none;
  padding: 10px 20px;
  margin-right: 10px;
  margin-bottom: 10px;

  &:hover, &:focus {
    background-color: darken($color, 15%);
    text-decoration: none;
  }
}

@mixin text-input {
  border: 1px solid #e7e6e6;
  padding: 6px 7px 5px;

  &:focus {
    outline: 1px solid black;
  }

}

// Full-width inside limited-width container.
@mixin full-width() {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

// Mobile first properties.
@mixin mobile-first {
  @media (max-width: $laptop - 1) {
    @content;
  }
}

// Desktop properties.
@mixin desktop {
  @media (min-width: $laptop) {
    @content;
  }
}

@mixin new-container {
  padding: 0 15px;
  margin: 0 auto;

  @media (min-width: $laptop) {
    max-width: 1440px;
    width: susy-span(11 of 12);
    padding: 0;
  }

  @media (min-width: $laptop-l) {
    width: susy-span(10 of 12);
  }
}

@mixin tableStyle {
  width: 100%;

  th,
  td {
    background: transparent;
    padding: 10px 5px;
  }

  tbody {

    tr {

      &:nth-child(odd) {
        background: $light-grey;
      }

      &:nth-child(even) {

      }

    }
  }

}

@mixin global-container {
  & {
    margin: 0 auto;
    max-width: $global-width;
    padding: 0 $global-padding-mobile;
    width: 100%;
  }
  @include breakpoint($tablet) {
    padding: 0 $global-padding-desktop;
  }
}

@mixin dashboard-container {
  padding: 0 $global-padding-mobile;
  margin: 0 auto;

  @include breakpoint($tablet) {
    max-width: 1440px;
    width: susy-span(11 of 12);
    padding: 0;
  }
}

@mixin gray-background-box {
  background-color: $generic-semi-transparent;
  border-radius: 5px;
  padding: 10px 20px;
}

@mixin second-gray-background-box {
  background-color: $generic-2-semi-transparent;
  border-radius: 5px;
  padding: 10px 20px;
}

// Redesign mixins (09/2019)
@mixin breakpoint($target, $down: false) {
  @if $down {
    @media (max-width: $target - 1) {
      @content;
    }
  } @else {
    @media (min-width: $target) {
      @content;
    }
  }
}

@mixin church-letter-container {
  @include global-container;

  @include breakpoint($laptop) {
    max-width: 66%;
  }
}

@mixin base-button {
  border: medium none;
  border-radius: 4px;
  color: $white;
  display: inline-block;
  font-size: $small-text;
  font-weight: bold;
  height: 42px;
  margin-bottom: 10px;
  padding: 13px 20px;
  position: relative;
  text-align: center;
  text-transform: uppercase;

  i {
    background-color: $white;
    border-radius: 50%;
    color: $green;
    display: none;
    font-size: $global-font-size;
    position: absolute;
    right: -5px;
    top: -5px;
  }

  &.checked {
    i {
      display: block;
    }
  }
}

@mixin solid-button($color) {
  @include base-button;

  & {
    background-color: $color;
  }
}

@mixin h-gradient-button($left_color, $right_color) {
  @include base-button;
  & {
    background-image: linear-gradient(to right, $left_color, $right_color);
  }
}

@mixin page-heading-image {
  position: relative;

  @include breakpoint($laptop) {
    max-height: 220px;
    overflow: hidden;
  }

  img {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    @include breakpoint($laptop) {
      height: auto;
      position: static;
      vertical-align: top;
      width: 100%;
    }
  }
}

@mixin page-heading-title {
  background-color: $generic-semi-transparent;
  color: $white;
  display: inline-block;
  font-size: $global-font-size;
  font-weight: bold;
  padding: 10px 20px;

  @include breakpoint($tablet) {
    margin-bottom: 20px;
  }

  span {
    font-weight: normal;
  }
}

@mixin section-title {
  border-bottom: 2px solid $light-blue;
  font-size: $medium-text;
  margin-bottom: 26px;
  padding: 15px 0;
  position: relative;

  @include breakpoint($laptop) {
    border: medium none;
    text-align: left;
  }

  span {
    display: inline-block;
  }
}

@mixin accordion-title {
  @include section-title;

  &::after {
    border-bottom: 2px solid $light-blue;
    border-right: 2px solid $light-blue;
    content: '';
    display: block;
    height: 10px;
    position: absolute;
    right: 0;
    top: 22px;
    transform: rotate(45deg);
    transition: transform .25s;
    width: 10px;

    @include breakpoint($laptop) {
      display: none;
    }
  }

  &.active {
    &::after {
      transform: rotate(225deg);
      top: 28px;
    }
  }
}

@mixin stat-box {
  @include global-container;
  & {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 10px $black-4;
  }

  @include breakpoint($tablet) {
    padding: 0 (calc($global-padding-mobile / 2));
  }

  @include breakpoint($laptop) {
    min-height: 170px;
  }
}

@mixin stat-item {
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  padding: 30px 0;
  position: relative;
  text-transform: uppercase;

  &:not(.separator) {
    @include breakpoint($tablet) {
      flex-basis: calc((100% / 3) - 25px);
      max-width:  calc(100% / 3);
    }
  }

  @include breakpoint($laptop) {
    min-height: 170px;
  }

  &.separator {
    position: relative;
    display: block;
    transform: none;
    padding: 0;

    &:after {
      display: block;
      content: '';
      width: 100%;
      height: 1px;
      background-color: #F5F6F8;
      position: absolute;
    }

    @include breakpoint($tablet) {
      max-width: 1px;

      &:after {
        height: 100%;
        max-height: calc(100% - 80px);
        width: 1px;
        top: 40px;
        bottom: -1px;
      }
    }
  }

}

@mixin accordion-hidden {
  display: none;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: height 500ms, opacity 300ms;

  @include breakpoint($laptop) {
    @include accordion-shown;
  }
}

@mixin accordion-shown {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: space-between;
  opacity: 1;
  visibility: visible;
  transition: height 500ms, opacity 300ms;

  @include breakpoint($tablet) {
    flex-wrap: unset;
  }
}

@mixin leader-card {
  background-color: $background-gray-3;
  border-radius: 3px;
  margin-bottom: 25px;
  padding: 20px 12px;
  width: 100%;

  @include breakpoint($laptop) {
    width: 35%;
  }

  .wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint($laptop) {
      flex-direction: row;
    }

    .left {
      flex-basis: 100%;

      @include breakpoint($laptop) {
        flex-basis: 20%;
      }
    }

    .right {
      flex-basis: 100%;
      text-align: center;

      @include breakpoint($laptop) {
        flex-basis: 80%;
        padding-left: 10px;
        text-align: left;
      }
    }

    .image {
      width: 60px;
      height: 60px;

      img {
        border-radius: 50%;
        height: 100%;
        object-fit: cover;
        vertical-align: middle;
        width: 100%;
      }
    }

    .position {
      color: $background-gray-1;
      font-size: $text-font-size;
      margin-bottom: 4px;
      text-transform: uppercase;
    }

    .name {
      color: $text-color;
      display: flex;
      flex-wrap: wrap;
      font-size: $global-font-size;
      font-weight: bold;
      line-height: $lisa-medium-text;
      margin-bottom: 4px;

      span {
        flex-basis: 100%;
        text-align: left;
      }
    }

    .phone {
      background: url("../images/icon-phone.svg") no-repeat left center;
      background-size: 14px;
      margin-bottom: 5px;
      padding-left: 25px;
    }

    .mail {
      background: url("../images/icon-mail.svg") no-repeat left center;
      background-size: 16px;
      color: $light-blue;
      font-size: $text-font-size;
      padding-left: 20px;
    }
  }
}

@mixin members-list {
  border-radius: 3px;
  box-shadow: 0px 0px 10px $black-5;
  padding: 12px 0;

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;

    li {
      flex-basis: 100%;

      @include breakpoint($tablet) {
        flex-basis: 33%;
      }

      @include breakpoint($laptop) {
        flex-basis: 25%;
      }
    }
  }

  .member {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    @include breakpoint($tablet) {
      justify-content: center;
    }

    .image {
      height: 50px;
      width: 50px;
      flex-grow: 0;
      flex-shrink: 0;
      position: relative;

      img {
        border-radius: 50%;
        height: 100%;
        object-fit: cover;
        vertical-align: middle;
        width: 100%;
      }

      .leader-action {
        position: absolute;
        right: -5px;
        bottom: -5px;

        a {
          font-size: 18px;
          outline: none;
        }

      }

    }

    .name {
      display: flex;
      width: calc(100% - 50px);
      font-size: $text-font-size;
      font-weight: bold;
      flex-grow: 0;
      flex-shrink: 0;
      flex-wrap: wrap;
      padding-left: 15px;

      span {
        flex-basis: 100%;
        text-align: left;
      }
    }
  }
}

@mixin action-stats {
  @include global-container;
  & {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
  }

  @include breakpoint($tablet) {
    padding: 0 $global-padding-mobile;
  }

  .tons,
  .kwh,
  .therms,
  .gas,
  .water {
    flex-basis: 50%;
    min-height: 90px;
    padding-right: 10px;

    @include breakpoint($tablet) {
      padding-left: 5%;
    }

    @include breakpoint($laptop) {
      flex-basis: 20%;
      flex-grow: 1;
      padding-left: 0;
      padding-right: 5px;
    }

    &:before {
      background-position: right top;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: block;
      float: left;
      height: 100%;
      margin-top: 6px;
      width: 40px;

      @include breakpoint($tablet) {
        margin: 6px 10px 0 0;
      }
    }
  }

  .tons,
  .kwh {
    &:before {
      background-image: url("../images/icon-kwh.svg");
    }
  }

  .therms {
    &:before {
      background-image: url("../images/icon-therms.svg");
    }
  }

  .gas {
    &:before {
      background-image: url("../images/icon-gas.svg");
    }
  }

  .water {
    &:before {
      background-image: url("../images/icon-water.svg");
    }
  }

  .value {
    color: $light-blue;
    font-size: 24px;
    font-weight: bold;

    @include breakpoint($laptop) {
      font-size: $medium-text;
    }
  }

  .label {
    color: $supporting-1;
    display: block;
    font-size: $text-font-size;
    text-transform: uppercase;
  }
}

@mixin see-all-links {
  font-weight: 700;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: right;

  a {
    color: $light-blue;
    font-size: $text-font-size;
  }
}

@mixin unordered-list {
  list-style-type: disc;
  padding-left: $global-padding-desktop;
}
